<template>
  <div class="uk-container uk-margin-medium-top">
    <div class="uk-grid">
      <div class="uk-width-3-4@m">
        <div class="uk-margin-small-bottom">
          <div class="vimeo-player">
            <youtube :video-id="$youtube.getIdFromUrl(video.video_url)" ref="youtube" :player-vars="{autoplay: 1, color: '#f6b923', modestbranding: 1, rel: 0}" />
          </div>
        </div>
        <h2 class="uk-margin-remove-top">{{ video.title }}</h2>
        <video-comments :about="about" type="elite-videos" :id="video.id" role="trader" v-if="is_loaded" />
      </div>
      <div class="uk-width-1-4@m"></div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      new_comment: {},
      new_reply: {},
      video: {},
      is_loaded: false,
    };
  },

  methods: {
    fetch() {
      this.$loader.start();
      this.is_loaded = false;

      this.$axios
        .get(`/api/v1/trader/conference/${this.$route.params.id}`)
        .then((response) => {
          this.video = response.data.video;
          this.$loader.stop();
          this.is_loaded = true;
        })
    },
  },

  computed: {
    about() {
      return `<h4>Date Published</h4><p>${this.$localizer.parse(this.video.date)}</p>`
    }
  }
};
</script>
